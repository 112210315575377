
import { defineComponent, reactive, onMounted, computed, PropType } from "vue";
import $API from "@/services";
import { AxiosResponse } from "axios";
import { IRequestResponse, RequestKindTypes } from "@/models/request";
import { IResponseData } from "@/models/common";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
import CreatePDF from "@/utils/pdf/CreatePDF";
import { pdfDoc } from "@/models/response";
import SaveToJpg from "@/utils/saveToJpg";
import { IDcotorListItem } from "@/models/doctor";
import { getDoctor } from "@/services/doctor";
import DoctorItem from "@/components/common/Doctors/DoctorItem.vue";
import FileList from "@/components/file/FileList.vue";
import { IFileListItem } from "@/models/file";
import Button from "primevue/button";

export default defineComponent({
  name: "Request Response",
  props: {
    id: {
      type: String,
      required: true,
    },
    kind: {
      type: String as PropType<RequestKindTypes>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      id: props.id,
      response: {} as IRequestResponse,
      files: [] as Array<IFileListItem>,
      printButton: true,
      doctor: null as IDcotorListItem | null,
      isLoading: true,
      kind: props.kind,
    });

    const { t } = useI18n({ useScope: "global" });

    const getResponse = async () => {
      const res: AxiosResponse<IResponseData<IRequestResponse>> =
        await $API.RequestService.getRequestResponse(Number.parseInt(state.id));
      const {
        data: { ResultData },
      } = res;

      if (Array.isArray(ResultData)) return {} as IRequestResponse;
      else return ResultData;
    };

    const getDoctorInfo = async (id: string) => {
      const doctorInfo = await getDoctor(id);
      return doctorInfo;
    };

    const getFiles = async () => {
      const ResultData = await $API.FileService.getFile(state.id);
      if (!Array.isArray(ResultData)) return;
      state.files = ResultData;
    };

    const responseDate = computed(() => {
      return `${state.response.ResponseDate} 회신`;
    });

    const result = computed(() => {
      return state.response.Opinions;
    });

    const pxFiles = computed(() =>
      state.files.filter((x) => x.PrescriptionFlag === 1 && x.FileStatus == 0)
    );
    const isPxFiles = computed(() => pxFiles.value.length > 0);

    onMounted(async () => {
      try {
        store.commit(CommonMutationTypes.SET_IS_LODING, true);
        if (state.id) {
          state.response = await getResponse();
          state.doctor = await getDoctorInfo(
            state.response.DoctorKey?.toString() ?? "0"
          );
          await getFiles();
          state.isLoading = false;
        }
      } catch (e) {
        console.error(e);
      } finally {
        state.isLoading = false;
        store.commit(CommonMutationTypes.SET_IS_LODING, false);
      }
    });

    const totalCallTime = computed(() => {
      return state.response?.Video.toString().split("\r\n");
    });

    const saveTojpg = async () => {
      try {
        state.printButton = false;
        const node = document.getElementById("requestResponse") as Node;
        await SaveToJpg.Save(node);
      } catch (e) {
        console.error(e);
      } finally {
        state.printButton = true;
      }
    };

    const opinions = computed(() => {
      return state.response
        ? state.response.Opinions
          ? state.response.Opinions.split("\n")
          : [""]
        : [""];
    });

    const createPDF = async () => {
      store.commit(CommonMutationTypes.SET_IS_LODING, true);
      const doc = new CreatePDF();
      const content: pdfDoc = {
        title: "OnlieCare " + t("doctor") + " " + t("response"),
        Opinions: !state.response
          ? ""
          : !state.response.Opinions
          ? ""
          : state.response.Opinions,

        info: [],
      };

      content.info.push({
        subTitle: t("doctor"),
        content: !state.response ? "" : state.response.DoctorInfo.toString(),
      });
      content.info.push({
        subTitle: t("scheduledDatetime"),
        content: !state.response
          ? ""
          : state.response.ScheduledDateTime.toString(),
      });

      content.info.push({
        subTitle: t("respondDateTime"),
        content: !state.response ? "" : state.response.ResponseDate.toString(),
      });

      await doc.createResponse(content);
      doc.savePDF();
      store.commit(CommonMutationTypes.SET_IS_LODING, false);
    };
    return {
      state,
      t,
      saveTojpg,
      createPDF,
      opinions,
      // otherComments,
      totalCallTime,
      responseDate,
      RequestKindTypes,
      result,
      getFiles,
      pxFiles,
      isPxFiles,
    };
  },
  components: {
    DoctorItem,
    FileList,
    Button,
  },
});
