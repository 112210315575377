import { IMyProfile } from "@/models/account";
import { pdfDoc } from "@/models/response";
import $API from "@/services";
import jsPDF from "jspdf";
import font from "./gulim";
import logo from "./heselLogo";

enum Aligns {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
  JUSTIFY = "justify"
}

class CreatePDF{
  private _doc;
  private _fonsSize = {
    title:16,
    content:13,
    info:10
  }  
  
  private _lineHeight=1.5;
  private _paddingH=30;
  private _paddingTop=30;
  private _paddingBottom=30;
  private _margin = 15;
  private _marginBottom = 25;
  private _pageWidth;
  private _maxLineWidth;
  private _ptsPerInch=2.83;
  private _pageHeight;
  private _y = this._paddingTop;
  private _oneLineHeight = (this._fonsSize.title * this._lineHeight) / this._ptsPerInch
  
  
  constructor(){
    this._doc = new jsPDF("p", "mm", "a4");
    this._doc.setLineHeightFactor(this._lineHeight);
    this._doc.addFileToVFS("malgun.ttf", font);
    this._doc.addFont("malgun.ttf", "malgun", "normal");
    this._doc.setFont("malgun");
    this._pageWidth = this._doc.internal.pageSize.width;
    this._pageHeight = this._doc.internal.pageSize.height;
    this._maxLineWidth = this._pageWidth - (this._paddingH * 2);    
  }
  async createResponse(content:pdfDoc){    
    this.setPage();    
    this._y = this._paddingTop;    

    this._oneLineHeight = (this._fonsSize.title ) / this._ptsPerInch
    //타이틀
    let textLines = this._doc.setFontSize(this._fonsSize.title).splitTextToSize(content.title.toString(), this._maxLineWidth);    
    this.text(textLines, Aligns.CENTER);
    this.line();

    this._oneLineHeight = (this._fonsSize.info ) / this._ptsPerInch
    // 정보
    content.info.forEach(x=>{
      textLines = this._doc.setFontSize(this._fonsSize.info).splitTextToSize(x.subTitle+" : "+x.content, this._maxLineWidth);
      // oneLineHeight = (this._fonsSize.info * this._lineHeight) / this._ptsPerInch
      this.text(textLines);      
    })
    this.line();
    
    this.text();    
    this._oneLineHeight = (this._fonsSize.content ) / this._ptsPerInch
    //환자정보
    const userInfo = await this.getUserInfo();
     textLines = this._doc.setFontSize(this._fonsSize.content).splitTextToSize((userInfo??"").toString(), this._maxLineWidth);
    // oneLineHeight = (this._fonsSize.content * this._lineHeight) / this._ptsPerInch
    this.text(textLines, Aligns.RIGHT);    
    this.line();

    this.text();    
    //opinions
    textLines = this._doc.setFontSize(this._fonsSize.content).splitTextToSize(content.Opinions.toString(), this._maxLineWidth);
    // oneLineHeight = (this._fonsSize.content * this._lineHeight) / this._ptsPerInch
    this.text(textLines);
  }

  async getUserInfo(){
      try {
        const profile: IMyProfile | null =
          await $API.AccountsService.getMyProfile();

        if (!profile) return "";

        return `${profile.LastName}${profile.FirstName} | ${profile.Sex} | ${profile.BirthDate}`;
        
      } catch (e) {
        console.error(e);
      }
  }

  setPage(){
    this._doc.line(this._margin, this._margin, this._pageWidth - this._margin, this._margin);
    this._doc.line(this._margin, this._pageHeight-this._marginBottom, this._pageWidth - this._margin, this._pageHeight-this._marginBottom);

    this._doc.line(this._margin, this._margin, this._margin, this._pageHeight-this._marginBottom);
    this._doc.line(this._pageWidth - this._margin, this._margin, this._pageWidth - this._margin, this._pageHeight-this._marginBottom);
    
    this.addLogo();
  }

  addLogo(){
    const width = 33;
    const height = 10.5;

    this._doc.addImage(logo, "png", this._pageWidth / 2 - width/2, this._pageHeight-height-10, width, height);
  }

  text(arrayText?: string[], align?:Aligns){ 
    if(!arrayText)
      arrayText = [""];
      
    arrayText.forEach((text)=>{
      
      if(this._y > this._pageHeight-this._paddingBottom){
        this._doc.addPage();
        this.setPage();
        this._y = this._paddingTop;        
      }

      if(align===Aligns.RIGHT) this._doc.text(text, this._pageWidth - this._paddingH, this._y, {align});
      else if(align===Aligns.CENTER) this._doc.text(text, this._pageWidth /2 , this._y, {align});
      else this._doc.text(text, this._paddingH, this._y);
        this._y+=this._oneLineHeight;
    })    
  }
  line(){
    this._doc.line(this._paddingH, this._y-this._oneLineHeight+2, this._pageWidth - this._paddingH, this._y-this._oneLineHeight+2);
    this._y+=2;
  }

  savePDF(){
    this._doc.save("prescription.pdf");
  }
}

export default CreatePDF;
