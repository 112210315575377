import DomToImage from "dom-to-image";
import { useStore } from "@/store";
import { CommonMutationTypes } from "@/store/modules/common/mutations";

class SaveToJpg{
  constructor(){}

  static async Save(node:Node){
    const store = useStore();

    store.commit(CommonMutationTypes.SET_IS_LODING, true);

    
    const dataUrl = await DomToImage.toJpeg(node);

    const link = document.createElement("a");
    link.download = "secondopinion-response.jpg";
    link.href = dataUrl;
    link.click();
    store.commit(CommonMutationTypes.SET_IS_LODING, false);    
  }
}

export default SaveToJpg;